<template>
  <main class="max-w-screen-sm">
    <slot name="header" />
    <slot />
  </main>
</template>

<script setup lang="ts">
  useHead({
    titleTemplate: (title) => {
      return title ? `${title} | ナビパーク` : 'ナビパーク';
    },
  });
</script>
